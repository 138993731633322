import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import styles from "./Output.module.css";
import img from "../Images/YSR logo.jpeg";
import api from "../../api";

function Output() {
  const { studentId } = useParams();
  const [studentDetails, setStudentDetails] = useState(null);
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchStudentDetails = async () => {
      try {
        const res = await api.getStudentsDetailsById(studentId);
        if (res.data.success) {
          setStudentDetails(res.data.data);
        } else {
          setMessage(
            "Error Fetching Student Details: " +
              (res.data.message || res.statusText)
          );
        }
      } catch (error) {
        if (error.response) {
          console.error("Error response data:", error.response.data);
          console.error("Error response status:", error.response.status);
          console.error("Error response headers:", error.response.headers);
        } else if (error.request) {
          console.error("Error request:", error.request);
        } else {
          console.error("Error message:", error.message);
        }
        console.error("Error config:", error.config);
        setMessage("An error occurred while fetching student details.");
      } finally {
        setLoading(false);
      }
    };

    fetchStudentDetails();
  }, [studentId]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (message) {
    return <div>{message}</div>;
  }

  if (!studentDetails) {
    return <div>No student details found.</div>;
  }

  return (
    <div className={styles.body}>
      <div className={styles.content}>
        <header className={styles.header}>
          <div className={styles.logo}>
            <img src={img} alt="" className={styles.img} />
          </div>
          <div className={styles.heading}>
            <h1 className={styles.main}>YSR EDUCATIONAL SERVICES</h1>
            <h6 className={styles.subMain}>
              "enlightening the value of education"
            </h6>
            <div>
              <p className={styles.addresses}>
                <span className={styles.address}>
                  Flat no. 6-36/4, Room no.4, 1st floor, opp. kukatpally bus
                  depot, moosapet,
                </span>
                <span className={styles.address2}>
                  Hyderabad - 500 018,T.S., India.
                  <span style={{ fontWeight: "bold", paddingLeft: "40px" }}>
                    Cell: 9959693939, 9133423939
                  </span>
                </span>
              </p>
            </div>
          </div>
        </header>
        <hr className={styles.line} />
        <h1 className={styles.subHead}>preliminary application form</h1>
        <div className={styles.date}>
          <label>Date:</label>
          <div className={styles.inputField}></div>
        </div>
        <table className={styles.tableOne}>
          <tbody>
            <tr>
              <th>
                1. Name of the applicant in full form
                <br />
                &nbsp;&nbsp;&nbsp; (In block Letters as per SSC)
              </th>
              <td className={styles.semiColon}>:</td>
              <td>
                <div name="applicantname" className={styles.inputField}>
                  {studentDetails.nameOfApplicant}
                </div>
              </td>
            </tr>
            <tr>
              <th>2. father name</th>
              <td className={styles.semiColon}>:</td>
              <td>
                <div className={styles.inputField}>
                  {studentDetails.fatherName}
                </div>
              </td>
            </tr>
            <tr>
              <th>3. Date of birth</th>
              <td className={styles.semiColon}>:</td>
              <td>
                <div className={styles.inputField}>
                  {studentDetails.dateOfBirth}
                </div>
              </td>
            </tr>
            <tr>
              <th>4. Address for communication</th>
              <td className={styles.semiColon}>:</td>
              <td>
                <div className={styles.inputField}>
                  {studentDetails.addressOfCommunication}
                </div>
              </td>
            </tr>
            <tr>
              <th>5. Tel.No/Mobile No.</th>
              <td className={styles.semiColon}>:</td>
              <td>
                <div className={styles.inputField}>
                  {studentDetails.phoneNumber}
                </div>
              </td>
            </tr>
            <tr>
              <th>6. Aadhar no.</th>
              <td className={styles.semiColon}>:</td>
              <td>
                <div className={styles.inputField}>
                  {studentDetails.aadharNo}
                </div>
              </td>
            </tr>
            <tr>
              <th>
                7. Category : management Quota/NRI
                <br />
                &nbsp;&nbsp;&nbsp; Foreign student quota(if Applicable)
              </th>
              <td className={styles.semiColon}>:</td>
              <td>
                <div className={styles.inputField}></div>
              </td>
            </tr>
            <tr>
              <th>8. OC/BC(A,B,C,D),SC/ST</th>
              <td className={styles.semiColon}>:</td>
              <td>
                <div className={styles.inputField}>
                  {studentDetails.category}
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <h6 className={styles.degree}>
          9. Details of qualifying Examination (10+2) or Degree
        </h6>
        <table className={styles.tableTwo} cellSpacing={0}>
          <tbody>
            <tr>
              <th style={{ width: "100px" }}>Course</th>
              <th>
                Board/
                <br />
                University
              </th>
              <th>
                Month & year <br />
                of passing
              </th>
              <th>
                Marks <br />
                obtained
              </th>
              <th>
                Percentage
                <br /> of Marks
              </th>
              <th>
                CEEP/EAMCET/ECET
                <br />
                /ICET/PGCET.HNo.
              </th>
              <th>
                CEEP/EAMCET/ECET
                <br />
                ICET/PGCET-Rank
              </th>
            </tr>
            <tr>
              <th>SSC</th>
              <td>
                <div>{studentDetails.qualifyingDetails.sscSchoolName}</div>
              </td>
              <td>
                <div>{studentDetails.qualifyingDetails.sscPassingYear}</div>
              </td>
              <td>
                <div></div>
              </td>
              <td>
                <div>{studentDetails.qualifyingDetails.sscPercentage}</div>
              </td>
              <td>
                <div>{studentDetails.qualifyingDetails.EAPCETHallTicketNo}</div>
              </td>
              <td>
                <div>{studentDetails.qualifyingDetails.EAPCETRank}</div>
              </td>
            </tr>
            <tr>
              <th>DIPLOMA</th>
              <td>
                <div></div>
              </td>
              <td>
                <div></div>
              </td>
              <td>
                <div></div>
              </td>
              <td>
                <div></div>
              </td>
              <td>
                <div></div>
              </td>
              <td>
                <div></div>
              </td>
            </tr>
            <tr>
              <th>INTER</th>
              <td>
                <div>{studentDetails.qualifyingDetails.hscSchoolName}</div>
              </td>
              <td>
                <div>{studentDetails.qualifyingDetails.hscPassingYear}</div>
              </td>
              <td>
                <div></div>
              </td>
              <td>
                <div>{studentDetails.qualifyingDetails.hscPercentage}</div>
              </td>
              <td>
                <div>{studentDetails.qualifyingDetails.EAPCETHallTicketNo}</div>
              </td>
              <td>
                <div>{studentDetails.qualifyingDetails.EAPCETRank}</div>
              </td>
            </tr>
            <tr>
              <th>DEGREE</th>
              <td>
                <div></div>
              </td>
              <td>
                <div></div>
              </td>
              <td>
                <div></div>
              </td>
              <td>
                <div></div>
              </td>
              <td>
                <div></div>
              </td>
              <td>
                <div></div>
              </td>
            </tr>
          </tbody>
        </table>
        <h6 style={{ marginTop: "2px", marginBottom: "2px" }}>
          10. Name of the institutions in which admission is required
          <div className={styles.inputThree}>
            {studentDetails.nameofInstution}
          </div>
        </h6>
        <h6 style={{ maginTop: "-3px" }}>
          11. Name of the course in which admission is required
        </h6>
        <div className={styles.check} style={{ marginTop: "-25px" }}>
          <p>
            A) polytechnic :
            <label htmlFor="polyeee" style={{ marginLeft: "1px" }}>
              EEE
            </label>
            <input type="checkbox" id="polyeee" name="poly" />
            <label htmlFor="polyece">ECE</label>
            <input type="checkbox" id="polyece" name="poly" />
            <label htmlFor="polymech">MECH</label>
            <input type="checkbox" id="polymech" name="poly" />
            <label htmlFor="polycivil">CIVIL</label>
            <input type="checkbox" id="polycivil" name="poly" />
          </p>
          <br />
          <p>
            B) B.TECH :
            <label htmlFor="btecheee" style={{ marginLeft: "2px" }}>
              EEE
            </label>
            <input type="checkbox" id="btecheee" name="btech" value="eee" />
            <label htmlFor="btechece">ECE</label>
            <input type="checkbox" id="btechece" name="btech" value="ece" />
            <label htmlFor="btechcse">CSE</label>
            <input type="checkbox" id="btechcse" name="btech" value="cse" />
            <label htmlFor="btechmech">MECH</label>
            <input type="checkbox" id="btechmech" name="btech" value="mech" />
            <label htmlFor="btechcivil">CIVIL</label>
            <input type="checkbox" id="btechcivil" name="btech" value="civil" />
            <label htmlFor="btechit">IT</label>
            <input type="checkbox" id="btechit" name="btech" value="it" />
          </p>
          <br />
          <p>
            C)
            <label htmlFor="bpharm" style={{ marginLeft: "2px" }}>
              B.PHARMACY
            </label>
            <input type="checkbox" id="bpharm" name="bpharm" value="bpharm" />
          </p>
          <br />
          <p>
            D)
            <label htmlFor="mbacheck" style={{ marginLeft: "2px" }}>
              MBA
            </label>
            <input type="checkbox" id="mbacheck" name="mba" value="mba" />
          </p>
          <br />
          <p>
            E) M.TECH :
            <label htmlFor="civilcheck" style={{ marginLeft: "2px" }}>
              CIVIL (SE,TE)
            </label>
            <input type="checkbox" id="civilcheck" name="mtech" value="civil" />
            <label htmlFor="ececheck">ECE(VLSI,ES,VLSI&ES)</label>
            <input type="checkbox" id="ececheck" name="mtech" value="ece" />
            <label htmlFor="eeecheck">EEE(PE/EPS)</label>
            <input type="checkbox" id="eeecheck" name="mtech" value="eee" />
          </p>
          <br />
          <p>
            <label htmlFor="mechcheck" style={{ marginLeft: "80px" }}>
              MECH(CAD/CAM/AMS/THERMAL,MD)
            </label>
            <input type="checkbox" id="mechcheck" name="mtech" value="mech" />
            <label htmlFor="cseCheck">CSE(CSE/SE)</label>
            <input type="checkbox" id="cseCheck" name="mtech" value="cse" />
          </p>
          <br />
          <p>
            F) M. PHARMACY :
            <label htmlFor="pharmacheck1" style={{ marginLeft: "3px" }}>
              PHARMACEUTICS
            </label>
            <input
              type="checkbox"
              id="pharmacheck1"
              name="pharma"
              value="pharmaceutics"
            />
            <label htmlFor="pmracheck2">PMRA</label>
            <input type="checkbox" id="pmracheck2" name="pharma" value="pmra" />
            <label htmlFor="paqacheck3">PAQA</label>
            <input type="checkbox" id="paqacheck3" name="pharma" value="paqa" />
            <label htmlFor="ipcheck4">IP</label>
            <input type="checkbox" id="ipcheck4" name="pharma" value="ip" />
            <label htmlFor="pccheck5">PC</label>
            <input type="checkbox" id="pccheck5" name="pharma" value="pc" />
          </p>
          <br />
          <p>
            <label htmlFor="mbbscheck" style={{ marginLeft: "0px" }}>
              G) MBBS
            </label>
            <input type="checkbox" id="mbbscheck" name="mbbs" value="mbbs" />
            <label htmlFor="bdscheck">H) BDS</label>
            <input type="checkbox" id="bdscheck" name="bds" value="bds" />
            <label htmlFor="agbsccheck">I) AgBSC</label>
            <input type="checkbox" id="agbsccheck" name="agbsc" value="agbsc" />
          </p>
        </div>
        <h6>
          12. Structure of payments: at a time/Year wise:
          <div className={styles.inputTwo}></div>
        </h6>
        <h6 className={styles.refer13}>
          13. with Reference of:
          <div className={styles.inputTwo}>
            {studentDetails.withReferenceOf}
          </div>
        </h6>
        <h6 className={styles.refer13}>
          14. Reference: Mention the name of two friends known to you with phone
          Numbers's
        </h6>
        <div className={styles.sign}>
          <div className={styles.count}>
            <span>
              1) <div className={styles.inputTwo}></div>
            </span>
            <span>
              2) <div className={styles.inputTwo}></div>
            </span>
          </div>
          <br />
          <br />
          <br />
          <div className={styles.inSign}>
            <div>signature of the parent</div>
            <div>signature of the student</div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Output;
