import { BrowserRouter } from "react-router-dom"; // BrowserRouter is now used inside Routes
import { Helmet } from "react-helmet";
import "./App.css";
import React from "react";
import { BrowserRouter as Router, Route, Routes, Link } from "react-router-dom";
import LandingPage from "./Components/LandingPage/LandingPage";
import Eapcet from "./Components/EAPCET/EapcetForm";
import Ecet from "./Components/ECET/EcetForm";
import Icet from "./Components/ICET/IcetForm";
import Pgcet from "./Components/PGCET/PgcetForm";
import Management from "./Components/Management/ManagementForm";
import Home from "./Components/MainPage/Home";
import About from "./Components/MainPage/About";
import Contact from "./Components/MainPage/Contact";
import RankChecker from "./Components/Ranks/CheckYourRank";
import FormSubmissionSuccess from "./sucess";
import StudentList from "./Components/Admin/StudentsList";
import ViewApplications from "./Components/Admin/ViewApplications";
import Output from "./Components/Output/Output";

function App() {
  return (
    <div>
      <div className="App">
        <Helmet>
          <title>YSR Educational Services</title>
          <link rel="icon" href="%public%/favicon.ico" />
        </Helmet>
      </div>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="/Admission-page" element={<LandingPage />} />
          <Route path="/EAPCET/student-form" element={<Eapcet />} />
          <Route path="/ECET/student-form" element={<Ecet />} />
          <Route path="/Icet/student-form" element={<Icet />} />
          <Route path="/PGCET/student-form" element={<Pgcet />} />
          <Route path="/Home" element={<Home />} />
          <Route path="/About-us" element={<About />} />
          <Route path="/Contact-us" element={<Contact />} />
          <Route path="/rank-checker" element={<RankChecker />} />
          <Route path="/success" element={<FormSubmissionSuccess />} />
          <Route
            path="/admin/allType-students-list"
            element={<StudentList />}
          />
          <Route
            path={"/admin/get-student-details-by-id/:studentId"}
            element={<Output />}
          />
          <Route path="/application-deatil-page" element={<Output />} />
          <Route path="*" element={<h2>404 Page Not Found</h2>} />
          <Route path="/" element={<LandingPage />} />
          <Route path="/" element={<LandingPage />} />
          <Route path="/" element={<LandingPage />} />
          <Route path="/" element={<LandingPage />} />
          <Route path="/Management/student-form" element={<Management />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
