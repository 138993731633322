import { HttpService } from "./http.service";

const eapcetFormSubmit = async (formData) => {
  return await HttpService.post(
    `/api/v1/user/create-student-registration`,
    formData
  );
};

const ecetFormSubmit = async (formData) => {
  return await HttpService.post(
    `/api/v1/user/ecet-student-registration`,
    formData
  );
};

const getAllEmployees = async (formData) => {
  return await HttpService.post(
    `/api/v1/user/emcet-student-registration`,
    formData
  );
};

const getStudentsDetailsById = async (studentId) => {
  return await HttpService.get(
    `/api/v1/admin/get-student-details-by-id/${studentId}`
  );
};

export default {
  eapcetFormSubmit,
  ecetFormSubmit,
  getAllEmployees,
  getStudentsDetailsById
};
