import React from "react";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #e1e9ee;
  text-align: center;
`;

const Message = styled.div`
  background: white;
  padding: 20px 40px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  font-size: 24px;
  color: #333;
  font-family: "Arial", sans-serif;
  transition: transform 0.3s ease-in-out;

  &:hover {
    transform: scale(2.05);
  }
`;

const Contact = () => {
  return (
    <Container>
      <Message>Page Coming Soon.............</Message>
    </Container>
  );
};

export default Contact;
