import React, { Component } from "react";
import LoGo from "../Images/YSRlogo.svg";
import "./Navbar.css";
import withNavigation from "./withNavigation"; // Import the HOC

class Navbar extends Component {
  state = { clicked: false };
  handleClick = () => {
    this.setState({ clicked: !this.state.clicked });
  };

  handleButtonClick = (actionType) => () => {
    const { navigate } = this.props; // Access navigate from props
    if (actionType === "Home") {
      navigate("/home");
    } else if (actionType === "About") {
      navigate("/about-us");
    } else if (actionType === "Contact") {
      navigate("/contact-us");
    } else if (actionType === "RankChecker") {
      navigate("/rank-checker");
    } else if (actionType === "Admission") {
      navigate("/admission-page");
    }
  };

  render() {
    return (
      <div>
        <nav>
          <a>
            <img
              src={LoGo}
              style={{
                width: "49px",
                height: "48px",
                viewBox: "0 0 49 48",
              }}
              alt="Logo"
            />
          </a>
          <div>
            <ul
              id="navbar"
              className={this.state.clicked ? "navbar active" : "#navbar"}
            >
              <li>
                <a onClick={this.handleButtonClick("Home")}>Home</a>
              </li>
              <li>
                <a onClick={this.handleButtonClick("About")}>About</a>
              </li>
              <li>
                <a onClick={this.handleButtonClick("Contact")}>Contact</a>
              </li>
              <li>
                <a onClick={this.handleButtonClick("RankChecker")}>
                  Check Your Rank
                </a>
              </li>
              <li>
                <a onClick={this.handleButtonClick("Admission")}>Admission</a>
              </li>
            </ul>
          </div>
          <div id="mobile" onClick={this.handleClick}>
            <i
              id="bar"
              className={this.state.clicked ? "fas fa-times" : "fas fa-bars"}
            ></i>
          </div>
        </nav>
      </div>
    );
  }
}

export default withNavigation(Navbar);