import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import {
  Container,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  CircularProgress,
  Button
} from "@mui/material";

const StudentList = () => {
  const [students, setStudents] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState("EAPCET");
  const navigate = useNavigate();

  useEffect(() => {
    const fetchStudents = async () => {
      try {
        setLoading(true);
        const response = await axios.get(
          `https://ysr-backend-1.onrender.com//api/v1/user/admin/allType-students-data/${selectedCategory}`
        );
        setStudents(response.data.data);
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchStudents();
  }, [selectedCategory]);

  const handleSelectChange = (event) => {
    setSelectedCategory(event.target.value);
  };

  const handleViewClick = (studentId) => {
    navigate(`/admin/get-student-details-by-id/${studentId}`);
  };

  if (loading) return <CircularProgress />;
  if (error)
    return <Typography color="error">Error: {error.message}</Typography>;

  return (
    <Container>
      <Typography variant="h4" gutterBottom>
        Student Details
      </Typography>
      <FormControl fullWidth variant="outlined" margin="normal">
        <InputLabel id="categorySelectLabel">Select Category</InputLabel>
        <Select
          labelId="categorySelectLabel"
          id="categorySelect"
          value={selectedCategory}
          onChange={handleSelectChange}
          label="Select Category"
        >
          <MenuItem value="All">All</MenuItem>
          <MenuItem value="EAPCET">EAPCET</MenuItem>
          <MenuItem value="ECET">ECET</MenuItem>
          <MenuItem value="PGCET">PGCET</MenuItem>
          <MenuItem value="MANAGAMENTS">MANAGAMENTS</MenuItem>
        </Select>
      </FormControl>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Created At</TableCell>
              <TableCell>Name of Applicant</TableCell>
              <TableCell>Father's Name</TableCell>
              <TableCell>Category</TableCell>
              <TableCell>Phone Number</TableCell>
              <TableCell>With Reference Of</TableCell>
              <TableCell>View</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {students.map((student) => (
              <TableRow key={student.id}>
                <TableCell>{student.id}</TableCell>
                <TableCell>
                  {new Date(student.createdAt).toLocaleString()}
                </TableCell>
                <TableCell>{student.nameOfApplicant}</TableCell>
                <TableCell>{student.fatherName}</TableCell>
                <TableCell>{student.category}</TableCell>
                <TableCell>{student.phoneNumber}</TableCell>
                <TableCell>{student.withReferenceOf}</TableCell>
                <TableCell>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => handleViewClick(student.id)}
                  >
                    View Application
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Container>
  );
};

export default StudentList;
